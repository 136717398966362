import { useRouting } from "expo-next-react-navigation";
import {
  RouteParams,
  RouteNames,
  routes,
  Redirect,
  getTabFromRoute,
} from "lib/routes";
import { Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { MapObjectKeysToValues } from "lib/types";

type NavigateWithOptions = Redirect & {
  shallow?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRouter = <RouteName extends RouteNames>(
  currentRoute?: RouteName,
) => {
  const {
    navigate,
    getParam: routeGetParam,
    goBack,
    replace,
    push,
  } = useRouting();

  const getParams = <
    Params extends keyof RouteParams[RouteName],
    ParamsArray extends Params[],
    ParamNames extends [...ParamsArray] | Params,
  >(
    params: ParamNames,
  ) => {
    const paramsArray = (
      typeof params === "string" ? [params] : params
    ) as string[];
    const result = paramsArray.map((name) => routeGetParam(name));

    return (
      typeof params === "string" ? result[0] : result
    ) as ParamNames extends Params
      ? RouteParams[RouteName][ParamNames]
      : MapObjectKeysToValues<ParamNames, RouteParams[RouteName]>;
  };

  const navigateWith =
    (fn: typeof navigate | typeof replace) =>
    async ({ shallow, ...redirectParams }: NavigateWithOptions) => {
      if (redirectParams.routeName === "Redirect") {
        await WebBrowser.openBrowserAsync(redirectParams.params.url);
        return;
      }

      const { routeName, params } = redirectParams;
      const tab = getTabFromRoute(routeName);
      const queryParams: Record<string, string> = {};
      let path: string = routes[routeName].path;

      if (params) {
        Object.entries(params).forEach(([key, value]) => {
          if (path.includes(`[${key}]`)) {
            path = path.replace(`[${key}]`, encodeURIComponent(value));
          } else if (value) {
            queryParams[key] = value;
          }
        });
      }

      return fn({
        routeName: tab ?? routeName,
        params: Platform.OS === "web" ? queryParams : params,
        web: {
          path,
          shallow,
        },
        native: {
          screen: routeName,
          initial: false,
        },
      });
    };

  return {
    navigate: navigateWith(navigate),
    replace: navigateWith(replace),
    push: navigateWith(push),
    getParams,
    routeName: currentRoute,
    goBack,
  };
};
